import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Context } from "./Context.jsx";

const FooterHome = ({configPath,preloadSestav,selectedModel}) => {
  let navigate = useNavigate();
  const {form, gwtReady, initializeInoAPI} = useContext(Context);
  const [active, setActive] = useState(false);

  const { t } = useTranslation();

  const onConfirm = () => {
    // if(window.dataLayer){
    //   window.dataLayer.push({
    //     event: "filter_configuration",
    //     filter_door_model: form.modelName,
    //     filter_door_programme: form.modelProgram,
    //     filter_door_model_type: form.modelType,
    //     filter_door_glass_amount: form.modelGlassAmount,
    //   });
    // }

    if (!active) {
      setActive(true);
    }

    if (preloadSestav) {
      initializeInoAPI(form.modelId, undefined, undefined, () => navigate(configPath))
      console.log("preloadSestavInit");
    } else {
      console.log("navigate");
      navigate(configPath + form.modelId);
    }
  };

  const preloadSestavInit = (modelId, modelName, onSestavLoaded) => {
    console.log('initialize');

    if (modelId || modelName) {
      window.inoAPI.loadSestav(modelId, 1, null, null, null, (resolved, error) => {
        console.log({resolved,error});
        if (resolved) {
          onSestavLoaded && onSestavLoaded();
        } else {
          console.log('Error Callback', error);
        }
      });
    }
    // setInoInitialized(true); //to force rerender
  };

  let opacityFooter = !active ? { opacity: 1 } : { opacity: 0.3 };

  return (
    <>
      {/* PC */}
      {form.modelName && (
        <footer className="footer footer-door-selector fixed-position mobile-hide">
          <div className="flex-line align-stretch">
            <div className="bg-dark-gray two-third mobile-hide">
              <div className="full-screen-container flex-line">
                <h3 className="white-font upper-case mobile-hide">
                  {t("chosenModel")} <strong>{form.modelName}</strong>
                </h3>
              </div>
            </div>
            <button style={opacityFooter} type="button" className={`home-button button-footer button-footer--orange upper-case one-third flex-line justify-center mobile-hide ${!gwtReady && "button-disabled"}`} onClick={() => onConfirm()}>
              {t("configureDoors")}
            </button>
          </div>
        </footer>
      )}
      {/* MOBILE - ALL TAGS HIDDEN ON DESKTOP */}
      {form.modelName && (
        <footer className="footer footer-door-selector fixed-position desktop-hide">
          <div className="flex-line align-stretch desktop-hide">
            <div className="bg-dark-gray two-third mobile-hide">
              <div className="full-screen-container flex-line">
                <h3 className="white-font upper-case mobile-hide">
                  {t("chosenModel")} <strong>{form.modelName}</strong>
                </h3>
              </div>
            </div>
            <button style={opacityFooter} type="button" className="home-button button-footer button-footer--orange upper-case full flex-line justify-center desktop-hide" onClick={() => onConfirm()}>
              {t("configureDoors")}
            </button>
          </div>
        </footer>
      )}
    </>
  );
};

export default FooterHome;
