import React, { useCallback, useState } from "react";

export default function useForm(data) {
  const initialState = data || {};
  const [form, setForm] = useState(initialState);

  const onComponentChange = useCallback((_form) => {
    setForm((prevForm) => ({
      ...prevForm,
      ..._form,
    }));
  }, []);

  const onInputChange = useCallback(
    (e) => {
      const name = e.target.name;
      const value = e.currentTarget.type === "checkbox" ? e.target.checked : e.target.value;

      if (!name) {
        onComponentChange(value);
      } else {
        onComponentChange({ [name]: value });
      }
    },
    [onComponentChange]
  );

  const onCancelClick = useCallback(() => {
    setForm(data);
  }, [data]);

  return {
    form,
    setForm,
    onInputChange,
    onComponentChange,
    onCancelClick,
  };
}
